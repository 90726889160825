const getters = {
  token: (state) => state.user.token,
  avatar: (state) => state.user.avatar,
  name: (state) => state.user.name,
  userId: (state) => state.user.userId,
  password: (state) => state.user.password,
  firstName: (state) => state.user.firstName,
  lastName: (state) => state.user.lastName,
  roles: (state) => state.user.roles,
  role: (state) => state.user.roles[0],
  socketChannel: (state) => state.user.socketChannel,
  isDoctorAvailable: (state) => state.user.isDoctorAvailable,
  waitingRooms: (state) => state.user.waitingRooms,
  isDarkThemeSelected: (state) => state.user.isDarkThemeSelected,
  alertsList: (state) => state.user.alerts,
  isShowDrawer: (state) => state.user.isShowDrawer,
  userPreferences: (state) => state.user.userPreferences,
  lang: (state) => state.user.lang,
  caseVitalsList: (state) => state.user.caseVitalsList,
  lastVitalInCase: (state) => state.user.lastVitalInCase,
  layout: (state) => state.user.layout,
  businessPartner: (state) => state.user.businessPartner,
  platformPartner: (state) => state.user.platformPartner,
  vitalsAlarmRange: (state) => state.vitalRanges.vitalRanges,
  caseMap: (state) => state.vitalRanges.caseMap,
  caseDetails: (state) => state.vitalRanges.caseDetails,
  isSidePanel: (state) => state.sidePanel.isSidePanel,
  currentView: (state) => state.sidePanel.currentView,
  currentViewTitle: (state) => state.sidePanel.title,
  getUhid: (state) => state.sidePanel.uhid,
  getMobileForm: (state) => state.sidePanel.mobileForm,
  getPatientData: (state) => state.sidePanel.patientData,
  getCurrentComponent: (state) => state.sidePanel.currentComponent,
  getCustomer: (state) => state.sidePanel.customer,
  isAlarmUpdate: (state) => state.sidePanel.isAlarmUpdate,
  isNurseMessageUpdate: (state) => state.sidePanel.isNurseMessageUpdate,
  isApl: (state) => state.sidePanel.isApl,
  isTable: (state) => state.user.isTable,
  isReportSummury: (state) => state.reportSummury.isReportSummury,
  getReportDetails: (state) => state.reportSummury.reportDetails,
  getPDfReport: (state) => state.reportSummury.pdfReport,
  getChartBase64: (state) => state.reportSummury.chartBase64,
  isUploaded: (state) => state.reportSummury.isUploaded,
  getEventsBase64: (state) => state.reportSummury.events,
  getScheduleInfo: (state) => state.videoConsultations.schedule,
  reportStatus: (state) => state.reportSummury.reportStatus,
  isVideoCallInitate: (state) => state.sidePanel.isVideoCallInitate,
  videoCallDetails: (state) => state.sidePanel.videoCallDetails,
  monitoringModeData: (state) => state.sidePanel.monitoringModeData,
  isEwsDialogData: (state) => state.sidePanel.isEwsDialogData,
  isEWSSummaryUpdate: (state) => state.sidePanel.isEWSSummaryUpdate,
  dashboardfilters: (state) => state.sidePanel.dashboardFilterParams
}

export default getters
