<template>
  <div class="app-wrapper">
    <navbar style="position: fixed; width: 100%; z-index: 9999" />
    <div class="main-container vigo-star__bg">
      <app-main />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LayOut',
  components: {
    Navbar: () => import(/* webpackPrefetch: true */ './components/Navbar'),
    AppMain: () => import(/* webpackPrefetch: true */ './components/AppMain')
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/mixin.scss";
.app-wrapper {
  @include clearfix;
  position: relative;
  min-height: 100%;
  width: 100%;
  padding: 0px 0px !important;
  background-color: #1B1B25;
}
</style>
