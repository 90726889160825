const sidePanel = {
  state: {
    isSidePanel: false,
    currentView: null,
    title: null,
    uhid: null,
    mobileForm: null,
    patientData: null,
    currentComponent: null,
    customer: null,
    isAlarmUpdate: false,
    isNurseMessageUpdate: false,
    isApl: false,
    isVideoCallInitate: false,
    videoCallDetails: null,
    monitoringModeData: {
      showMonitoringModeDialog: false,
      successDialog: false,
      currentMode: null
    },
    isEwsDialogData: { isDialog: false, data: null },
    isEWSSummaryUpdate: false,
    dashboardFilterParams: ''
  },
  mutations: {
    SET_SIDE_PANEL_OPEN: (state, data) => {
      state.isSidePanel = data
    },
    SET_CURRENT_VIEW: (state, data) => {
      state.currentView = data
    },
    SET_CURRENT_VIEW_TITLE: (state, data) => {
      state.title = data
    },
    SET_UHID: (state, data) => {
      state.uhid = data
    },
    SET_MOBILE_FORM: (state, data) => {
      state.mobileForm = data
    },
    SET_PATIENT_DATA: (state, data) => {
      state.patientData = data
    },
    SET_CURRENT_COMPONENT: (state, data) => {
      state.currentComponent = data
    },
    SET_CUSTOMER: (state, data) => {
      state.customer = data
    },
    SET_IS_UPDATE_ALARM: (state, data) => {
      state.isAlarmUpdate = data
    },
    SET_IS_UPDATE_NURSE_MESSAGES: (state, data) => {
      state.isNurseMessageUpdate = data
    },
    SET_APL: (state, data) => {
      state.isApl = data
    },
    SET_IS_VIDEO_CALL: (state, data) => {
      state.isVideoCallInitate = data
    },
    SET_IS_VIDEO_CALL_DATA: (state, data) => {
      state.videoCallDetails = data
    },
    SET_MONITORING_MODE_DATA: (state, data) => {
      state.monitoringModeData = data
    },
    SET_EWS_DIALOG: (state, data) => {
      state.isEwsDialogData = data
    },
    SET_EWS_SUMMARY_UPDATE: (state, data) => {
      state.isEWSSummaryUpdate = data
    },
    SET_DASHBOARD_FILTERS: (state, data) => {
      state.dashboardFilterParams = data
    }
  },
  actions: {
    setSidePanel({ commit }, data) {
      commit('SET_SIDE_PANEL_OPEN', data.sidePanel)
      commit('SET_CURRENT_VIEW', data.view)
      commit('SET_CURRENT_VIEW_TITLE', data.title)
      commit('SET_CURRENT_COMPONENT', data.component ? data.component : null)
    },
    setUhid({ commit }, data) {
      commit('SET_UHID', data)
    },
    setMobileForm({ commit }, data) {
      commit('SET_MOBILE_FORM', data)
    },
    setPatientData({ commit }, data) {
      commit('SET_PATIENT_DATA', data)
    },
    setCustomer({ commit }, data) {
      commit('SET_CUSTOMER', data)
    },
    setApl({ commit }, data) {
      commit('SET_APL', data)
    },
    setIsAlarmUpdate({ commit }, data) {
      commit('SET_IS_UPDATE_ALARM', data)
    },
    setIsNurseMessageUpdate({ commit }, data) {
      commit('SET_IS_UPDATE_NURSE_MESSAGES', data)
    },
    setIsVideoCallDetails({ commit }, data) {
      commit('SET_IS_VIDEO_CALL', data.open)
      commit('SET_IS_VIDEO_CALL_DATA', data.callDetails)
    },
    setMonitoringData({ commit }, data) {
      commit('SET_MONITORING_MODE_DATA', data)
    },
    openEwsDialog({ commit }, data) {
      commit('SET_EWS_DIALOG', data)
    },
    setIsEWSSummaryUpdate({ commit }, data) {
      commit('SET_EWS_SUMMARY_UPDATE', data)
    },
    setDashboardFilters({ commit }, data) {
      commit('SET_DASHBOARD_FILTERS', data)
    }
  }
}

export default sidePanel
